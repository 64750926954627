import React from 'react'
import ReactDOM from 'react-dom'
import './assets/css/index.css'
import App from './App'
import reportWebVitals from './core/reportWebVitals'
import './core/i18n'
import QueryContextProvider from "./core/contexts/query";


ReactDOM.render(
	<React.StrictMode>
		<QueryContextProvider>
			<App />
		</QueryContextProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
