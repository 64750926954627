import { RiContactsBook2Line } from 'react-icons/ri'

const HistoryIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.02 4.46997L12 2'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M4.90988 7.79877C3.79988 9.27877 3.10986 11.1088 3.10986 13.1088C3.10986 18.0188 7.08988 21.9988 11.9999 21.9988C16.9099 21.9988 20.8899 18.0188 20.8899 13.1088C20.8899 8.19876 16.9099 4.21875 11.9999 4.21875C11.3199 4.21875 10.6599 4.3088 10.0199 4.4588'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const DepositIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8 12H16'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12 16V8'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const WithdrawIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8 12H16'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const InternalTransferIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16 8H8V16H16V8Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5 22C6.65 22 8 20.65 8 19V16H5C3.35 16 2 17.35 2 19C2 20.65 3.35 22 5 22Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5 8H8V5C8 3.35 6.65 2 5 2C3.35 2 2 3.35 2 5C2 6.65 3.35 8 5 8Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M16 8H19C20.65 8 22 6.65 22 5C22 3.35 20.65 2 19 2C17.35 2 16 3.35 16 5V8Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M19 22C20.65 22 22 20.65 22 19C22 17.35 20.65 16 19 16H16V19C16 20.65 17.35 22 19 22Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const WarnIcon = ({ color }) => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3.74189 15.1237L8.69189 17.982C9.50023 18.4487 10.5002 18.4487 11.3169 17.982L16.2586 15.1237C17.0669 14.657 17.5669 13.7904 17.5669 12.8487L17.5669 7.1487C17.5669 6.21536 17.0669 5.3487 16.2586 4.8737L11.3086 2.01537C10.5002 1.5487 9.50023 1.5487 8.68356 2.01536L3.73356 4.8737C2.92523 5.34036 2.42523 6.20703 2.42523 7.1487L2.42523 12.8487C2.43356 13.7904 2.93356 14.6487 3.74189 15.1237ZM10.6252 13.5404C10.6252 13.882 10.3419 14.1654 10.0002 14.1654C9.65856 14.1654 9.37523 13.882 9.37523 13.5404L9.37523 9.16536C9.37523 8.8237 9.65856 8.54036 10.0002 8.54036C10.3419 8.54036 10.6252 8.8237 10.6252 9.16536L10.6252 13.5404ZM9.23356 6.14036C9.27523 6.04036 9.33356 5.9487 9.40856 5.86537C9.56689 5.70703 9.77523 5.6237 10.0002 5.6237C10.1086 5.6237 10.2169 5.6487 10.3169 5.69036C10.4252 5.73203 10.5086 5.79036 10.5919 5.86537C10.6669 5.9487 10.7252 6.04036 10.7752 6.14037C10.8169 6.24037 10.8336 6.3487 10.8336 6.45703C10.8336 6.6737 10.7502 6.89036 10.5919 7.0487C10.5086 7.1237 10.4252 7.18203 10.3169 7.2237C10.0086 7.35703 9.64189 7.28203 9.40856 7.0487C9.33356 6.96536 9.27523 6.88203 9.23356 6.7737C9.19189 6.6737 9.16689 6.56536 9.16689 6.45703C9.16689 6.3487 9.19189 6.24037 9.23356 6.14036Z'
			fill={color || '#0773F1'}
		/>
	</svg>
)

const DashboardIcon = ({ color }) => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill={color || 'none'}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z'
			fill={color || '#A6A9B9'}
		/>
		<path
			d='M18.6699 2H16.7699C14.5899 2 13.4399 3.15 13.4399 5.33V7.23C13.4399 9.41 14.5899 10.56 16.7699 10.56H18.6699C20.8499 10.56 21.9999 9.41 21.9999 7.23V5.33C21.9999 3.15 20.8499 2 18.6699 2Z'
			fill={color || '#A6A9B9'}
		/>
		<path
			d='M18.6699 13.4297H16.7699C14.5899 13.4297 13.4399 14.5797 13.4399 16.7597V18.6597C13.4399 20.8397 14.5899 21.9897 16.7699 21.9897H18.6699C20.8499 21.9897 21.9999 20.8397 21.9999 18.6597V16.7597C21.9999 14.5797 20.8499 13.4297 18.6699 13.4297Z'
			fill={color || '#A6A9B9'}
		/>
		<path
			d='M7.24 13.4297H5.34C3.15 13.4297 2 14.5797 2 16.7597V18.6597C2 20.8497 3.15 21.9997 5.33 21.9997H7.23C9.41 21.9997 10.56 20.8497 10.56 18.6697V16.7697C10.57 14.5797 9.42 13.4297 7.24 13.4297Z'
			fill={color || '#A6A9B9'}
		/>
	</svg>
)

const DocumentIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16 2H8C4.5 2 3 4 3 7V17C3 20 4.5 22 8 22H16C19.5 22 21 20 21 17V7C21 4 19.5 2 16 2ZM8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25ZM16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75ZM18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z'
			fill={color || '#A6A9B9'}
		/>
	</svg>
)

const OtcTradeIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2 11V9C2 5.5 4 4 7 4H17C20 4 22 5.5 22 9V15C22 18.5 20 20 17 20H12'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M18.5 9.5V14.5'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 15.5H7.34003C7.98003 15.5 8.5 16.02 8.5 16.66V17.94'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.21997 14.2812L2 15.5012L3.21997 16.7212'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.5 20.7798H3.15997C2.51997 20.7798 2 20.2598 2 19.6198V18.3398'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.28125 22.0025L8.50122 20.7825L7.28125 19.5625'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const SpotTradeIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M2 17.0586H12'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M4.21997 13.5H9.78003C11.56 13.5 12 13.94 12 15.7V19.81C12 21.57 11.56 22.01 9.78003 22.01H4.21997C2.43997 22.01 2 21.57 2 19.81V15.7C2 13.94 2.43997 13.5 4.21997 13.5Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M22 15C22 18.87 18.87 22 15 22L16.05 20.25'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 9C2 5.13 5.13 2 9 2L7.95001 3.75'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M18.5 11C20.9853 11 23 8.98528 23 6.5C23 4.01472 20.9853 2 18.5 2C16.0147 2 14 4.01472 14 6.5C14 8.98528 16.0147 11 18.5 11Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const BalancesIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13 11.1484H7'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 11.1498V6.52985C2 4.48985 3.65 2.83984 5.69 2.83984H11.31C13.35 2.83984 15 4.10984 15 6.14984'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M17.48 12.1984C16.98 12.6784 16.74 13.4184 16.94 14.1784C17.19 15.1084 18.11 15.6984 19.07 15.6984H20V17.1484C20 19.3584 18.21 21.1484 16 21.1484H6C3.79 21.1484 2 19.3584 2 17.1484V10.1484C2 7.93844 3.79 6.14844 6 6.14844H16C18.2 6.14844 20 7.94844 20 10.1484V11.5984H18.92C18.36 11.5984 17.85 11.8184 17.48 12.1984Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M22 12.6216V14.6816C22 15.2416 21.5399 15.7016 20.9699 15.7016H19.0399C17.9599 15.7016 16.97 14.9116 16.88 13.8316C16.82 13.2016 17.0599 12.6116 17.4799 12.2016C17.8499 11.8216 18.36 11.6016 18.92 11.6016H20.9699C21.5399 11.6016 22 12.0616 22 12.6216Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const BottomBarBalancesIcon = ({ color }) => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M22.6916 12.6216V14.6816C22.6916 15.2416 22.2316 15.7016 21.6616 15.7016H19.7316C18.6516 15.7016 17.6616 14.9116 17.5716 13.8316C17.5116 13.2016 17.7516 12.6116 18.1716 12.2016C18.5416 11.8216 19.0516 11.6016 19.6116 11.6016H21.6616C22.2316 11.6016 22.6916 12.0616 22.6916 12.6216Z'
			fill={color || '#191D31'}
			fillOpacity={color ? '1' : '0.5'}
		/>
		<path
			d='M16.0753 13.9584C15.9853 12.9084 16.3653 11.8784 17.1253 11.1284C17.7653 10.4784 18.6553 10.0984 19.6153 10.0984H20.1853C20.4653 10.0984 20.6953 9.86844 20.6553 9.58844C20.3853 7.64844 18.7053 6.14844 16.6953 6.14844H6.69531C4.48531 6.14844 2.69531 7.93844 2.69531 10.1484V17.1484C2.69531 19.3584 4.48531 21.1484 6.69531 21.1484H16.6953C18.7153 21.1484 20.3853 19.6484 20.6553 17.7084C20.6953 17.4284 20.4653 17.1984 20.1853 17.1984H19.7353C17.8353 17.1984 16.2353 15.7784 16.0753 13.9584ZM13.6953 11.8984H7.69531C7.28531 11.8984 6.94531 11.5684 6.94531 11.1484C6.94531 10.7284 7.28531 10.3984 7.69531 10.3984H13.6953C14.1053 10.3984 14.4453 10.7384 14.4453 11.1484C14.4453 11.5584 14.1053 11.8984 13.6953 11.8984Z'
			fill={color || '#191D31'}
			fillOpacity={color ? '1' : '0.5'}
		/>
		<path
			d='M14.902 3.98156C15.162 4.25156 14.932 4.65156 14.552 4.65156H6.72205C5.63205 4.65156 4.61205 4.97156 3.76205 5.52156C3.37205 5.77156 2.84205 5.50156 3.03205 5.07156C3.59205 3.76156 4.90205 2.85156 6.41205 2.85156H12.032C13.192 2.85156 14.222 3.26156 14.902 3.98156Z'
			fill={color || '#191D31'}
			fillOpacity={color ? '1' : '0.5'}
		/>
	</svg>
)

const ProfileIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.45006 10.79 7.56006 8.84 7.56006 6.44C7.56006 3.99 9.54006 2 12.0001 2C14.4501 2 16.4401 3.99 16.4401 6.44C16.4301 8.84 14.5401 10.79 12.1601 10.87Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.15997 14.56C4.73997 16.18 4.73997 18.82 7.15997 20.43C9.90997 22.27 14.42 22.27 17.17 20.43C19.59 18.81 19.59 16.17 17.17 14.56C14.43 12.73 9.91997 12.73 7.15997 14.56Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const SettingsIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 12.8814V11.1214C2 10.0814 2.85 9.2214 3.9 9.2214C5.71 9.2214 6.45 7.9414 5.54 6.3714C5.02 5.4714 5.33 4.3014 6.24 3.7814L7.97 2.7914C8.76 2.3214 9.78 2.6014 10.25 3.3914L10.36 3.5814C11.26 5.1514 12.74 5.1514 13.65 3.5814L13.76 3.3914C14.23 2.6014 15.25 2.3214 16.04 2.7914L17.77 3.7814C18.68 4.3014 18.99 5.4714 18.47 6.3714C17.56 7.9414 18.3 9.2214 20.11 9.2214C21.15 9.2214 22.01 10.0714 22.01 11.1214V12.8814C22.01 13.9214 21.16 14.7814 20.11 14.7814C18.3 14.7814 17.56 16.0614 18.47 17.6314C18.99 18.5414 18.68 19.7014 17.77 20.2214L16.04 21.2114C15.25 21.6814 14.23 21.4014 13.76 20.6114L13.65 20.4214C12.75 18.8514 11.27 18.8514 10.36 20.4214L10.25 20.6114C9.78 21.4014 8.76 21.6814 7.97 21.2114L6.24 20.2214C5.33 19.7014 5.02 18.5314 5.54 17.6314C6.45 16.0614 5.71 14.7814 3.9 14.7814C2.85 14.7814 2 13.9214 2 12.8814Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const BottomBarSettingsIcon = ({ color }) => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M19.84 5.42141L14.67 2.43141C13.68 1.86141 12.13 1.86141 11.14 2.43141L5.91998 5.44141C3.84998 6.84141 3.72998 7.05141 3.72998 9.28141V14.7114C3.72998 16.9414 3.84998 17.1614 5.95998 18.5814L11.13 21.5714C11.63 21.8614 12.27 22.0014 12.9 22.0014C13.53 22.0014 14.17 21.8614 14.66 21.5714L19.88 18.5614C21.95 17.1614 22.07 16.9514 22.07 14.7214V9.28141C22.07 7.05141 21.95 6.84141 19.84 5.42141ZM12.9 15.2514C11.11 15.2514 9.64998 13.7914 9.64998 12.0014C9.64998 10.2114 11.11 8.75141 12.9 8.75141C14.69 8.75141 16.15 10.2114 16.15 12.0014C16.15 13.7914 14.69 15.2514 12.9 15.2514Z'
			fill={color || '#191D31'}
			fillOpacity={color ? '1' : '0.5'}
		/>
	</svg>
)

const NotificationIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.0201 2.91016C8.71009 2.91016 6.02009 5.60016 6.02009 8.91016V11.8002C6.02009 12.4102 5.76009 13.3402 5.45009 13.8602L4.30009 15.7702C3.59009 16.9502 4.08009 18.2602 5.38009 18.7002C9.69009 20.1402 14.3401 20.1402 18.6501 18.7002C19.8601 18.3002 20.3901 16.8702 19.7301 15.7702L18.5801 13.8602C18.2801 13.3402 18.0201 12.4102 18.0201 11.8002V8.91016C18.0201 5.61016 15.3201 2.91016 12.0201 2.91016Z'
			stroke={color || '#2E3344'}
			strokeWidth='1.2'
			strokeMiterlimit='10'
			strokeLinecap='round'
		/>
		<path
			d='M13.8699 3.20141C13.5599 3.11141 13.2399 3.04141 12.9099 3.00141C11.9499 2.88141 11.0299 2.95141 10.1699 3.20141C10.4599 2.46141 11.1799 1.94141 12.0199 1.94141C12.8599 1.94141 13.5799 2.46141 13.8699 3.20141Z'
			stroke={color || '#2E3344'}
			strokeWidth='1.2'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15.02 19.0586C15.02 20.7086 13.67 22.0586 12.02 22.0586C11.2 22.0586 10.44 21.7186 9.90002 21.1786C9.36002 20.6386 9.02002 19.8786 9.02002 19.0586'
			stroke={color || '#2E3344'}
			strokeWidth='1.2'
			strokeMiterlimit='10'
		/>
	</svg>
)

const TradeIcon = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM10.99 17C10.99 17.1 10.97 17.19 10.93 17.29C10.85 17.47 10.71 17.62 10.52 17.7C10.43 17.74 10.33 17.76 10.23 17.76C10.13 17.76 10.04 17.74 9.94 17.7C9.85 17.66 9.77 17.61 9.7 17.54L6.75 14.59C6.46 14.3 6.46 13.82 6.75 13.53C7.04 13.24 7.52 13.24 7.81 13.53L9.48 15.2V7C9.48 6.59 9.82 6.25 10.23 6.25C10.64 6.25 10.99 6.59 10.99 7V17ZM17.24 10.48C17.09 10.63 16.9 10.7 16.71 10.7C16.52 10.7 16.33 10.63 16.18 10.48L14.51 8.81V17C14.51 17.41 14.17 17.75 13.76 17.75C13.35 17.75 13.01 17.41 13.01 17V7C13.01 6.9 13.03 6.81 13.07 6.71C13.15 6.53 13.29 6.38 13.48 6.3C13.66 6.22 13.87 6.22 14.05 6.3C14.14 6.34 14.22 6.39 14.29 6.46L17.24 9.41C17.53 9.71 17.53 10.19 17.24 10.48Z'
			fill={color || 'white'}
		/>
	</svg>
)

const ChartIcon = ({ color }) => (
	<svg
		width='25'
		height='24'
		viewBox='0 0 25 24'
		fill={color || 'none'}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M16.4898 2H8.1098C4.4698 2 2.2998 4.17 2.2998 7.81V16.18C2.2998 19.83 4.4698 22 8.1098 22H16.4798C20.1198 22 22.2898 19.83 22.2898 16.19V7.81C22.2998 4.17 20.1298 2 16.4898 2ZM10.2098 16.19C10.2098 16.83 9.6898 17.35 9.0398 17.35C8.3998 17.35 7.8798 16.83 7.8798 16.19V12.93C7.8798 12.29 8.3998 11.77 9.0398 11.77C9.6898 11.77 10.2098 12.29 10.2098 12.93V16.19ZM16.7198 16.19C16.7198 16.83 16.1998 17.35 15.5598 17.35C14.9098 17.35 14.3898 16.83 14.3898 16.19V7.81C14.3898 7.17 14.9098 6.65 15.5598 6.65C16.1998 6.65 16.7198 7.17 16.7198 7.81V16.19Z'
			fill={color || '#191D31'}
			fillOpacity={color ? '1' : '0.5'}
		/>
	</svg>
)

const VerifyIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M21.5599 10.7386L20.1999 9.15859C19.9399 8.85859 19.7299 8.29859 19.7299 7.89859V6.19859C19.7299 5.13859 18.8599 4.26859 17.7999 4.26859H16.0999C15.7099 4.26859 15.1399 4.05859 14.8399 3.79859L13.2599 2.43859C12.5699 1.84859 11.4399 1.84859 10.7399 2.43859L9.16988 3.80859C8.86988 4.05859 8.29988 4.26859 7.90988 4.26859H6.17988C5.11988 4.26859 4.24988 5.13859 4.24988 6.19859V7.90859C4.24988 8.29859 4.03988 8.85859 3.78988 9.15859L2.43988 10.7486C1.85988 11.4386 1.85988 12.5586 2.43988 13.2486L3.78988 14.8386C4.03988 15.1386 4.24988 15.6986 4.24988 16.0886V17.7986C4.24988 18.8586 5.11988 19.7286 6.17988 19.7286H7.90988C8.29988 19.7286 8.86988 19.9386 9.16988 20.1986L10.7499 21.5586C11.4399 22.1486 12.5699 22.1486 13.2699 21.5586L14.8499 20.1986C15.1499 19.9386 15.7099 19.7286 16.1099 19.7286H17.8099C18.8699 19.7286 19.7399 18.8586 19.7399 17.7986V16.0986C19.7399 15.7086 19.9499 15.1386 20.2099 14.8386L21.5699 13.2586C22.1499 12.5686 22.1499 11.4286 21.5599 10.7386ZM16.1599 10.1086L11.3299 14.9386C11.1899 15.0786 10.9999 15.1586 10.7999 15.1586C10.5999 15.1586 10.4099 15.0786 10.2699 14.9386L7.84988 12.5186C7.55988 12.2286 7.55988 11.7486 7.84988 11.4586C8.13988 11.1686 8.61988 11.1686 8.90988 11.4586L10.7999 13.3486L15.0999 9.04859C15.3899 8.75859 15.8699 8.75859 16.1599 9.04859C16.4499 9.33859 16.4499 9.81859 16.1599 10.1086Z'
			fill={color || '#191D31'}
		/>
	</svg>
)

const SecurityIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M18.54 4.17063L13.04 2.11062C12.47 1.90063 11.54 1.90063 10.97 2.11062L5.47005 4.17063C4.41005 4.57063 3.55005 5.81063 3.55005 6.94063V15.0406C3.55005 15.8506 4.08005 16.9206 4.73005 17.4006L10.23 21.5106C11.2 22.2406 12.79 22.2406 13.76 21.5106L19.26 17.4006C19.91 16.9106 20.4401 15.8506 20.4401 15.0406V6.94063C20.4501 5.81063 19.59 4.57063 18.54 4.17063ZM12.75 12.8706V15.5006C12.75 15.9106 12.41 16.2506 12 16.2506C11.59 16.2506 11.25 15.9106 11.25 15.5006V12.8706C10.24 12.5506 9.50005 11.6106 9.50005 10.5006C9.50005 9.12062 10.62 8.00063 12 8.00063C13.38 8.00063 14.5 9.12062 14.5 10.5006C14.5 11.6206 13.76 12.5506 12.75 12.8706Z'
			fill={color || '#191D31'}
		/>
	</svg>
)

const BottomBarOtcIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M17 4H7C4 4 2 5.5 2 9V12.56C2 12.93 2.38 13.16 2.71 13.01C3.69 12.56 4.82 12.39 6.01 12.6C8.64 13.07 10.57 15.51 10.5 18.18C10.49 18.6 10.43 19.01 10.32 19.41C10.24 19.72 10.49 20.01 10.81 20.01H17C20 20.01 22 18.51 22 15.01V9C22 5.5 20 4 17 4ZM12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5ZM19.25 14C19.25 14.41 18.91 14.75 18.5 14.75C18.09 14.75 17.75 14.41 17.75 14V10C17.75 9.59 18.09 9.25 18.5 9.25C18.91 9.25 19.25 9.59 19.25 10V14Z'
			fill={color || '#191D31'}
		/>
		<path
			d='M5 14C3.75 14 2.62 14.59 1.89 15.49C1.33 16.18 1 17.05 1 18C1 20.21 2.8 22 5 22C6.74 22 8.23 20.89 8.77 19.33C8.92 18.92 9 18.47 9 18C9 15.8 7.21 14 5 14ZM7.36 19.74C7.34 19.8 7.3 19.86 7.26 19.9L6.54 20.61C6.45 20.71 6.33 20.75 6.2 20.75C6.07 20.75 5.94 20.71 5.85 20.61C5.69 20.46 5.67 20.22 5.77 20.04H3.76C3.12 20.04 2.6 19.52 2.6 18.87V18.77C2.6 18.49 2.82 18.28 3.09 18.28C3.36 18.28 3.58 18.49 3.58 18.77V18.87C3.58 18.98 3.66 19.07 3.77 19.07H5.78C5.68 18.88 5.7 18.65 5.86 18.49C6.05 18.3 6.36 18.3 6.54 18.49L7.26 19.21C7.3 19.25 7.34 19.31 7.37 19.37C7.41 19.48 7.41 19.62 7.36 19.74ZM7.4 17.23C7.4 17.51 7.18 17.72 6.91 17.72C6.64 17.72 6.42 17.51 6.42 17.23V17.13C6.42 17.02 6.34 16.93 6.23 16.93H4.23C4.33 17.12 4.31 17.35 4.15 17.51C4.06 17.6 3.94 17.65 3.8 17.65C3.68 17.65 3.55 17.6 3.46 17.51L2.74 16.79C2.7 16.75 2.66 16.69 2.63 16.63C2.59 16.51 2.59 16.38 2.63 16.26C2.66 16.21 2.69 16.14 2.74 16.1L3.46 15.39C3.65 15.19 3.96 15.19 4.14 15.39C4.3 15.54 4.32 15.78 4.22 15.96H6.23C6.87 15.96 7.39 16.48 7.39 17.13V17.23H7.4Z'
			fill={color || '#191D31'}
		/>
	</svg>
)

const BottomBarSpotIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12 15.7V15.81C12 16.09 11.78 16.31 11.5 16.31H2.5C2.22 16.31 2 16.09 2 15.81V15.7C2 13.94 2.44 13.5 4.22 13.5H9.78C11.56 13.5 12 13.94 12 15.7Z'
			fill={color || '#191D31'}
		/>
		<path
			d='M2.5 17.3086C2.22 17.3086 2 17.5286 2 17.8086V18.8086V19.7986C2 21.5586 2.44 21.9986 4.22 21.9986H9.78C11.56 21.9986 12 21.5586 12 19.7986V18.8086V17.8086C12 17.5286 11.78 17.3086 11.5 17.3086H2.5Z'
			fill={color || '#191D31'}
		/>
		<path
			d='M22 15C22 18.87 18.87 22 15 22L16.05 20.25'
			stroke={color || '#191D31'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 9C2 5.13 5.13 2 9 2L7.95 3.75'
			stroke={color || '#191D31'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M18.5 11C20.9853 11 23 8.98528 23 6.5C23 4.01472 20.9853 2 18.5 2C16.0147 2 14 4.01472 14 6.5C14 8.98528 16.0147 11 18.5 11Z'
			fill={color || '#191D31'}
		/>
	</svg>
)

const FilterIcon = ({ color }) => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M4.49967 1.75H15.4997C16.4163 1.75 17.1663 2.5 17.1663 3.41667V5.25C17.1663 5.91667 16.7497 6.75 16.333 7.16667L12.7497 10.3333C12.2497 10.75 11.9163 11.5833 11.9163 12.25V15.8333C11.9163 16.3333 11.583 17 11.1663 17.25L9.99967 18C8.91634 18.6667 7.41634 17.9167 7.41634 16.5833V12.1667C7.41634 11.5833 7.08301 10.8333 6.74967 10.4167L3.58301 7.08333C3.16634 6.66667 2.83301 5.91667 2.83301 5.41667V3.5C2.83301 2.5 3.58301 1.75 4.49967 1.75Z'
			stroke={color || '#D9D9D9'}
			strokeWidth='1.25'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M9.10833 1.75L5 8.33333'
			stroke={color || '#D9D9D9'}
			strokeWidth='1.25'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const BuySellIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M22 8.5C22 12.09 19.09 15 15.5 15C15.33 15 15.15 14.99 14.98 14.98C14.73 11.81 12.19 9.26999 9.01999 9.01999C9.00999 8.84999 9 8.67 9 8.5C9 4.91 11.91 2 15.5 2C19.09 2 22 4.91 22 8.5Z'
			stroke={color || '#d9d9d9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15 15.5C15 19.09 12.09 22 8.5 22C4.91 22 2 19.09 2 15.5C2 11.91 4.91 9 8.5 9C8.67 9 8.84999 9.00999 9.01999 9.01999C12.19 9.26999 14.73 11.81 14.98 14.98C14.99 15.15 15 15.33 15 15.5Z'
			stroke={color || '#d9d9d9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M7.62 14.62L8.5 13L9.38 14.62L11 15.5L9.38 16.38L8.5 18L7.62 16.38L6 15.5L7.62 14.62Z'
			stroke={color || '#d9d9d9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const PinIcon = ({ color }) => (
	<svg width='12' height='20' viewBox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M5.99984 20.0003V11.3337M5.99984 11.3337C7.41433 11.3337 8.77088 10.7718 9.77107 9.77156C10.7713 8.77137 11.3332 7.41481 11.3332 6.00033C11.3332 4.58584 10.7713 3.22928 9.77107 2.22909C8.77088 1.2289 7.41433 0.666992 5.99984 0.666992C4.58535 0.666992 3.22879 1.2289 2.2286 2.22909C1.22841 3.22928 0.666504 4.58584 0.666504 6.00033C0.666504 7.41481 1.22841 8.77137 2.2286 9.77156C3.22879 10.7718 4.58535 11.3337 5.99984 11.3337Z'
			stroke={color || '#CAD2D3'}
			fill={color || ''}
		/>
	</svg>
)

const SecurityIcon2 = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M20.9101 11.1203C20.9101 16.0103 17.3601 20.5903 12.5101 21.9303C12.1801 22.0203 11.8201 22.0203 11.4901 21.9303C6.64008 20.5903 3.09009 16.0103 3.09009 11.1203V6.73028C3.09009 5.91028 3.7101 4.98028 4.4801 4.67028L10.0501 2.39031C11.3001 1.88031 12.7101 1.88031 13.9601 2.39031L19.5301 4.67028C20.2901 4.98028 20.9201 5.91028 20.9201 6.73028L20.9101 11.1203Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const SettingsIcon2 = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 12.8814V11.1214C2 10.0814 2.85 9.2214 3.9 9.2214C5.71 9.2214 6.45 7.9414 5.54 6.3714C5.02 5.4714 5.33 4.3014 6.24 3.7814L7.97 2.7914C8.76 2.3214 9.78 2.6014 10.25 3.3914L10.36 3.5814C11.26 5.1514 12.74 5.1514 13.65 3.5814L13.76 3.3914C14.23 2.6014 15.25 2.3214 16.04 2.7914L17.77 3.7814C18.68 4.3014 18.99 5.4714 18.47 6.3714C17.56 7.9414 18.3 9.2214 20.11 9.2214C21.15 9.2214 22.01 10.0714 22.01 11.1214V12.8814C22.01 13.9214 21.16 14.7814 20.11 14.7814C18.3 14.7814 17.56 16.0614 18.47 17.6314C18.99 18.5414 18.68 19.7014 17.77 20.2214L16.04 21.2114C15.25 21.6814 14.23 21.4014 13.76 20.6114L13.65 20.4214C12.75 18.8514 11.27 18.8514 10.36 20.4214L10.25 20.6114C9.78 21.4014 8.76 21.6814 7.97 21.2114L6.24 20.2214C5.33 19.7014 5.02 18.5314 5.54 17.6314C6.45 16.0614 5.71 14.7814 3.9 14.7814C2.85 14.7814 2 13.9214 2 12.8814Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const UserEditIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M19.2101 15.7371L15.67 19.2771C15.53 19.4171 15.4 19.6771 15.37 19.8671L15.18 21.2171C15.11 21.7071 15.45 22.0471 15.94 21.9771L17.29 21.7871C17.48 21.7571 17.75 21.6271 17.88 21.4871L21.42 17.9471C22.03 17.3371 22.32 16.6271 21.42 15.7271C20.53 14.8371 19.8201 15.1271 19.2101 15.7371Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M18.7002 16.25C19.0002 17.33 19.8402 18.17 20.9202 18.47'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.40991 22C3.40991 18.13 7.25994 15 11.9999 15C13.0399 15 14.0399 15.15 14.9699 15.43'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const InviteFriendsIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16.5 21.5V17.5'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.1601 10.87C12.0601 10.86 11.9401 10.86 11.8301 10.87C9.4501 10.79 7.5601 8.84 7.5601 6.44C7.5501 3.99 9.5401 2 11.9901 2C14.4401 2 16.4301 3.99 16.4301 6.44C16.4301 8.84 14.5301 10.79 12.1601 10.87Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.99 21.8097C10.17 21.8097 8.36004 21.3497 6.98004 20.4297C4.56004 18.8097 4.56004 16.1697 6.98004 14.5597C9.73004 12.7197 14.24 12.7197 16.99 14.5597'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14.5 19.5L18.5 19.5'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const GoogleIcon = () => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M21.8055 10.0415H21V10H12V14H17.6515C16.827 16.3285 14.6115 18 12 18C8.6865 18 6 15.3135 6 12C6 8.6865 8.6865 6 12 6C13.5295 6 14.921 6.577 15.9805 7.5195L18.809 4.691C17.023 3.0265 14.634 2 12 2C6.4775 2 2 6.4775 2 12C2 17.5225 6.4775 22 12 22C17.5225 22 22 17.5225 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
			fill='#FFC107'
		/>
		<path
			d='M3.15308 7.3455L6.43858 9.755C7.32758 7.554 9.48058 6 12.0001 6C13.5296 6 14.9211 6.577 15.9806 7.5195L18.8091 4.691C17.0231 3.0265 14.6341 2 12.0001 2C8.15908 2 4.82808 4.1685 3.15308 7.3455Z'
			fill='#FF3D00'
		/>
		<path
			d='M12.0002 22.0003C14.5832 22.0003 16.9302 21.0118 18.7047 19.4043L15.6097 16.7853C14.5719 17.5745 13.3039 18.0014 12.0002 18.0003C9.39916 18.0003 7.19066 16.3418 6.35866 14.0273L3.09766 16.5398C4.75266 19.7783 8.11366 22.0003 12.0002 22.0003Z'
			fill='#4CAF50'
		/>
		<path
			d='M21.8055 10.0415H21V10H12V14H17.6515C17.2571 15.1082 16.5467 16.0766 15.608 16.7855L15.6095 16.7845L18.7045 19.4035C18.4855 19.6025 22 17 22 12C22 11.3295 21.931 10.675 21.8055 10.0415Z'
			fill='#1976D2'
		/>
	</svg>
)

const MobileIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M16.6668 5.83464V14.168C16.6668 17.5013 15.8335 18.3346 12.5002 18.3346H7.50016C4.16683 18.3346 3.3335 17.5013 3.3335 14.168V5.83464C3.3335 2.5013 4.16683 1.66797 7.50016 1.66797H12.5002C15.8335 1.66797 16.6668 2.5013 16.6668 5.83464Z'
			stroke='#A6A9B9'
			strokeOpacity='0.5'
			strokeWidth='1.25'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.6668 4.58398H8.3335'
			stroke='#A6A9B9'
			strokeOpacity='0.5'
			strokeWidth='1.25'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M10.0002 15.9173C10.7135 15.9173 11.2918 15.339 11.2918 14.6257C11.2918 13.9123 10.7135 13.334 10.0002 13.334C9.2868 13.334 8.7085 13.9123 8.7085 14.6257C8.7085 15.339 9.2868 15.9173 10.0002 15.9173Z'
			stroke='#A6A9B9'
			strokeOpacity='0.5'
			strokeWidth='1.25'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const MailIcon = () => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M14.1667 17.0827H5.83341C3.33341 17.0827 1.66675 15.8327 1.66675 12.916V7.08268C1.66675 4.16602 3.33341 2.91602 5.83341 2.91602H14.1667C16.6667 2.91602 18.3334 4.16602 18.3334 7.08268V12.916C18.3334 15.8327 16.6667 17.0827 14.1667 17.0827Z'
			stroke='#A6A9B9'
			strokeOpacity='0.5'
			strokeWidth='1.25'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14.1666 7.5L11.5582 9.58333C10.6999 10.2667 9.29158 10.2667 8.43325 9.58333L5.83325 7.5'
			stroke='#A6A9B9'
			strokeOpacity='0.5'
			strokeWidth='1.25'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const PersonalCardIcon = ({ color, size }) => (
	<svg
		width={size || '25'}
		height={size || '24'}
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M17.5 21H7.5C3.5 21 2.5 20 2.5 16V8C2.5 4 3.5 3 7.5 3H17.5C21.5 3 22.5 4 22.5 8V16C22.5 20 21.5 21 17.5 21Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M14.5 8H19.5'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M15.5 12H19.5'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M17.5 16H19.5'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.99994 11.2899C9.99958 11.2899 10.8099 10.4796 10.8099 9.47992C10.8099 8.48029 9.99958 7.66992 8.99994 7.66992C8.00031 7.66992 7.18994 8.48029 7.18994 9.47992C7.18994 10.4796 8.00031 11.2899 8.99994 11.2899Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.5 16.3298C12.36 14.8798 11.21 13.7398 9.76 13.6098C9.26 13.5598 8.75 13.5598 8.24 13.6098C6.79 13.7498 5.64 14.8798 5.5 16.3298'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const VerifyIcon2 = ({ color }) => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.2499 2.45031C11.9399 1.86031 13.0699 1.86031 13.7699 2.45031L15.3499 3.81031C15.6499 4.07031 16.2099 4.28031 16.6099 4.28031H18.3099C19.3699 4.28031 20.2399 5.15031 20.2399 6.21031V7.91031C20.2399 8.30031 20.4499 8.87031 20.7099 9.17031L22.0699 10.7503C22.6599 11.4403 22.6599 12.5703 22.0699 13.2703L20.7099 14.8503C20.4499 15.1503 20.2399 15.7103 20.2399 16.1103V17.8103C20.2399 18.8703 19.3699 19.7403 18.3099 19.7403H16.6099C16.2199 19.7403 15.6499 19.9503 15.3499 20.2103L13.7699 21.5703C13.0799 22.1603 11.9499 22.1603 11.2499 21.5703L9.66988 20.2103C9.36988 19.9503 8.80988 19.7403 8.40988 19.7403H6.67988C5.61988 19.7403 4.74988 18.8703 4.74988 17.8103V16.1003C4.74988 15.7103 4.53988 15.1503 4.28988 14.8503L2.93988 13.2603C2.35988 12.5703 2.35988 11.4503 2.93988 10.7603L4.28988 9.17031C4.53988 8.87031 4.74988 8.31031 4.74988 7.92031V6.20031C4.74988 5.14031 5.61988 4.27031 6.67988 4.27031H8.40988C8.79988 4.27031 9.36988 4.06031 9.66988 3.80031L11.2499 2.45031Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.87988 12.0001L11.2899 14.4201L16.1199 9.58008'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const UserIcon = ({ color, size = '24' }) => (
	<svg
		width={size}
		height={size}
		viewBox='0 0 25 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12.5 12C15.2614 12 17.5 9.76142 17.5 7C17.5 4.23858 15.2614 2 12.5 2C9.73858 2 7.5 4.23858 7.5 7C7.5 9.76142 9.73858 12 12.5 12Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M21.0899 22C21.0899 18.13 17.2399 15 12.4999 15C7.75991 15 3.90991 18.13 3.90991 22'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const SmsIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z'
			stroke={color}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M17 9L13.87 11.5C12.84 12.32 11.15 12.32 10.12 11.5L7 9'
			stroke={color}
			strokeWidth='1.5'
			strokeMiterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const SelfieIcon = ({ color }) => (
	<svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13.03 20.4201H6.71C3.55 20.4201 2.5 18.3201 2.5 16.2101V7.79008C2.5 4.63008 3.55 3.58008 6.71 3.58008H13.03C16.19 3.58008 17.24 4.63008 17.24 7.79008V16.2101C17.24 19.3701 16.18 20.4201 13.03 20.4201Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M20.02 17.1001L17.24 15.1501V8.84013L20.02 6.89013C21.38 5.94013 22.5 6.52013 22.5 8.19013V15.8101C22.5 17.4801 21.38 18.0601 20.02 17.1001Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12 11C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.1716 8 10.5 8.67157 10.5 9.5C10.5 10.3284 11.1716 11 12 11Z'
			stroke={color}
			strokeOpacity='0.5'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const UploadIcon = () => (
	<svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12.0341 6.54531C11.8758 6.54531 11.7174 6.48698 11.5924 6.36198L9.90078 4.67031L8.20911 6.36198C7.96745 6.60365 7.56745 6.60365 7.32578 6.36198C7.08411 6.12031 7.08411 5.72031 7.32578 5.47865L9.45912 3.34531C9.70078 3.10365 10.1008 3.10365 10.3424 3.34531L12.4758 5.47865C12.7174 5.72031 12.7174 6.12031 12.4758 6.36198C12.3508 6.48698 12.1924 6.54531 12.0341 6.54531Z'
			fill='#0773F1'
		/>
		<path
			d='M9.89844 12.9437C9.55677 12.9437 9.27344 12.6604 9.27344 12.3187V3.84375C9.27344 3.50208 9.55677 3.21875 9.89844 3.21875C10.2401 3.21875 10.5234 3.50208 10.5234 3.84375V12.3187C10.5234 12.6687 10.2401 12.9437 9.89844 12.9437Z'
			fill='#0773F1'
		/>
		<path
			d='M9.9987 17.7917C5.70703 17.7917 2.70703 14.7917 2.70703 10.5C2.70703 10.1583 2.99036 9.875 3.33203 9.875C3.6737 9.875 3.95703 10.1583 3.95703 10.5C3.95703 14.0583 6.44036 16.5417 9.9987 16.5417C13.557 16.5417 16.0404 14.0583 16.0404 10.5C16.0404 10.1583 16.3237 9.875 16.6654 9.875C17.007 9.875 17.2904 10.1583 17.2904 10.5C17.2904 14.7917 14.2904 17.7917 9.9987 17.7917Z'
			fill='#0773F1'
		/>
	</svg>
)

const TrashIcon = ({ size }) => (
	<svg
		width={size || '16'}
		height={size || '17'}
		viewBox='0 0 16 17'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M14 4.48568C11.78 4.26568 9.54667 4.15234 7.32 4.15234C6 4.15234 4.68 4.21901 3.36 4.35234L2 4.48568'
			stroke='#E94D4D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M5.66602 3.81203L5.81268 2.9387C5.91935 2.30536 5.99935 1.83203 7.12602 1.83203H8.87268C9.99935 1.83203 10.086 2.33203 10.186 2.94536L10.3327 3.81203'
			stroke='#E94D4D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M12.5669 6.59375L12.1336 13.3071C12.0603 14.3537 12.0003 15.1671 10.1403 15.1671H5.86026C4.00026 15.1671 3.94026 14.3537 3.86693 13.3071L3.43359 6.59375'
			stroke='#E94D4D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6.88672 11.5H9.10672'
			stroke='#E94D4D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M6.33398 8.83203H9.66732'
			stroke='#E94D4D'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const CardsIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3.92969 15.8797L15.8797 3.92969'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.1016 18.2781L12.3016 17.0781'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M13.793 15.5892L16.183 13.1992'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			stroke-miterlimit='10'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M3.60127 10.2375L10.2413 3.59754C12.3613 1.47754 13.4213 1.46754 15.5213 3.56754L20.4313 8.47754C22.5313 10.5775 22.5213 11.6375 20.4013 13.7575L13.7613 20.3975C11.6413 22.5175 10.5813 22.5275 8.48127 20.4275L3.57127 15.5175C1.47127 13.4175 1.47127 12.3675 3.60127 10.2375Z'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M2 22H22'
			stroke={color || '#A6A9B9'}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

const ContactsIcon = ({ color }) => <RiContactsBook2Line size={24} color={color || '#A6A9B9'} />

const BadgeIcon = ({ size }) => (
	<svg width={size || '11'} height={size || '11'} viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='5.5' cy='5.5' r='5.5' fill='#CB4F4F' />
	</svg>
)

const TriangleIcon = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 18 16' fill={color} xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M11.1651 1.75L16.3612 10.75C17.3235 12.4167 16.1207 14.5 14.1962 14.5H3.80385C1.87935 14.5 0.676534 12.4167 1.63878 10.75L6.83494 1.75C7.79719 0.0833342 10.2028 0.0833342 11.1651 1.75Z'
			stroke={color}
		/>
	</svg>
)

const CopyIcon = ({ color }) => (
	<svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M8.325 17.5625H5.175C2.2425 17.5625 0.9375 16.2575 0.9375 13.325V10.175C0.9375 7.2425 2.2425 5.9375 5.175 5.9375H8.325C11.2575 5.9375 12.5625 7.2425 12.5625 10.175V13.325C12.5625 16.2575 11.2575 17.5625 8.325 17.5625ZM5.175 7.0625C2.85 7.0625 2.0625 7.85 2.0625 10.175V13.325C2.0625 15.65 2.85 16.4375 5.175 16.4375H8.325C10.65 16.4375 11.4375 15.65 11.4375 13.325V10.175C11.4375 7.85 10.65 7.0625 8.325 7.0625H5.175V7.0625Z'
			fill={color || 'white'}
		/>
		<path
			d='M12.825 13.0625H12C11.6925 13.0625 11.4375 12.8075 11.4375 12.5V10.175C11.4375 7.85 10.65 7.0625 8.325 7.0625H6C5.6925 7.0625 5.4375 6.8075 5.4375 6.5V5.675C5.4375 2.7425 6.7425 1.4375 9.675 1.4375H12.825C15.7575 1.4375 17.0625 2.7425 17.0625 5.675V8.825C17.0625 11.7575 15.7575 13.0625 12.825 13.0625ZM12.5625 11.9375H12.825C15.15 11.9375 15.9375 11.15 15.9375 8.825V5.675C15.9375 3.35 15.15 2.5625 12.825 2.5625H9.675C7.35 2.5625 6.5625 3.35 6.5625 5.675V5.9375H8.325C11.2575 5.9375 12.5625 7.2425 12.5625 10.175V11.9375Z'
			fill={color || 'white'}
		/>
	</svg>
)

const InvoiceIcon = ({ color }) => (
	<svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M36.666 9.9987V14.032C36.666 16.6654 34.9993 18.332 32.366 18.332H26.666V6.68203C26.666 4.83203 28.1827 3.33203 30.0327 3.33203C31.8494 3.3487 33.516 4.08203 34.716 5.28203C35.916 6.4987 36.666 8.16536 36.666 9.9987Z'
			fill='#F7931A'
			stroke='#F7931A'
			strokeWidth='1.5'
			strokeLiterlimit='10'
			strokeLinecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M3.33398 11.6654V34.9987C3.33398 36.382 4.90061 37.1654 6.00061 36.332L8.85065 34.1987C9.51731 33.6987 10.4507 33.7654 11.0507 34.3654L13.8173 37.1487C14.4673 37.7987 15.534 37.7987 16.184 37.1487L18.984 34.3487C19.5673 33.7654 20.5007 33.6987 21.1507 34.1987L24.0007 36.332C25.1007 37.1487 26.6673 36.3654 26.6673 34.9987V6.66536C26.6673 4.83203 28.1673 3.33203 30.0007 3.33203H11.6673H10.0007C5.00065 3.33203 3.33398 6.31536 3.33398 9.9987V11.6654Z'
			stroke='#F7931A'
			strokeWidth='1.5'
			strokeLiterlimit='10'
			strokeLinecap='round'
			stroke-linejoin='round'
		/>
		<path
			d='M10 15H20'
			stroke='#F7931A'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M11.25 21.668H18.75'
			stroke='#F7931A'
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
)

export {
	WarnIcon,
	BalancesIcon,
	DashboardIcon,
	OtcTradeIcon,
	ProfileIcon,
	SettingsIcon,
	SpotTradeIcon,
	NotificationIcon,
	BottomBarSettingsIcon,
	TradeIcon,
	ChartIcon,
	VerifyIcon,
	SecurityIcon,
	BottomBarOtcIcon,
	BottomBarSpotIcon,
	DepositIcon,
	WithdrawIcon,
	HistoryIcon,
	FilterIcon,
	BuySellIcon,
	PinIcon,
	InviteFriendsIcon,
	SecurityIcon2,
	SettingsIcon2,
	UserEditIcon,
	GoogleIcon,
	MobileIcon,
	MailIcon,
	SmsIcon,
	UserIcon,
	VerifyIcon2,
	PersonalCardIcon,
	SelfieIcon,
	UploadIcon,
	TrashIcon,
	CardsIcon,
	BadgeIcon,
	TriangleIcon,
	DocumentIcon,
	BottomBarBalancesIcon,
	CopyIcon,
	ContactsIcon,
	InternalTransferIcon,
	InvoiceIcon,
}
