import { createContext, useContext, useEffect, useState } from "react";
import { MutationCache, QueryCache, QueryClient } from "react-query";


const context = createContext()

export const useQueryContext = () => {
    return useContext(context)
}

const QueryContextProvider = ({ children }) => {

    const [queryClient, setQueryClient] = useState(new QueryClient())
    const [toast, setToast] = useState({
        show: false, message: "", isError: null
    })

    useEffect(() => {
        const client = new QueryClient({
            queryCache: new QueryCache({
                onError: (res) => {
                    setToast({
                        message: res.response.data.message,
                        show: true,
                        isError: true
                    })
                }
            }),
            mutationCache: new MutationCache({
                onError: (res) => {
                    setToast({
                        message: res.response.data.message,
                        show: true,
                        isError: true
                    })
                }
            })
        })
        setQueryClient(client)
    }, [])

    return (
        <context.Provider
            value={{
                queryClient,
                toast,
                setToast
            }}
        >
            {children}
        </context.Provider>
    )
}

export default QueryContextProvider
