import React, {useEffect, useRef, useState} from 'react'
import Pagination from 'react-js-pagination'
import SearchBox from '../../SearchBox'
import useSearchApi from '../../../core/hooks/useSearchApi'
import {DText, Flex, FlexCenter, PaginationContainer, SubmitSearch} from '../../../styles/CommonStyles'
import {HeaderColumn, HeaderRow, NoDataWrapper} from '../../../styles/TableStyle'
import Text from '../../../core/utils/Text'
import {useMainContext} from '../../../core/contexts/main'
import AuthLoading from '../../authentication/AuthLoading'
import {useWindowSize} from '../../../core/hooks/useWindowSize'
import {TABLET_SIZE} from '../../../core/constants/common'
import {
	FilterButton,
	FilterCheckBox,
	FilterContent,
	FiltersRow,
	FiltersText,
	FiltersWrapper,
	Filters, FilterTag
} from "../../../styles/layout-styles/CommonStyles";
import {AnimatePresence} from "framer-motion";
import {filters as FILTERS, getFilterDate} from "../../../core/utils/filter";
import useClickOutside from "../../../core/hooks/useClickOutside";
import { FilterIcon } from '../../common/icons'


const RFilterLayout = ({
	children,
	state,
	headers,
	data,
	loading,
	totalPages,
	filters,
	hasSearchBar,
	activeTab = -1,
} = {}) => {
	const { width } = useWindowSize()

	const {
		main: { theme },
	} = useMainContext()
	const [searchValue, setSearchValue] = useState('')

	const filtersRef = useRef()
	const [filtersOpen, setFiltersOpen] = useState(false)
	const [filtersOn, setFiltersOn] = useState([])

	const [_filters, setFilters] = useState({})

	useEffect(() => {
		const temp = {}
		filters.forEach(filter => {
			temp[filter] = -1
		})
		setFilters(temp)
	}, [])

	useEffect(() => {
		filtersReset()
	}, [activeTab])

	useClickOutside(filtersRef, () => setFiltersOpen(false))

	const { filterQueries, setFilterQueries, initialState } = state
	const [
		onCloseSearchClicked,
		onPageChange,
		requestOrdersByMarket
	] = useSearchApi(activeTab, state)

	const onEnterToggled = (e) => {
		if (e.keyCode === 13) {
			requestOrdersByMarket(searchValue)
		}
	}

	const onCloseSearchClick = () => {
		setSearchValue('')
		onCloseSearchClicked()
	}

	const onFilterClicked = (type, idx) => {
		setFilters(state => ({...state, [type]: idx}))
	}

	const onSubmitClicked = () => {
		setFiltersOpen(false)
		setFilterQueries(state => {

			const newQuery = {}
			let temp = []
			Object.keys(_filters).forEach(key => {
				const value = _filters[key]
				if (value >= 0) {
					temp.push({key, value: FILTERS[key][value]})
				}
				if (key === 'date') {
					return
				}

				let _value = FILTERS[key][value]
				if (key === 'status') {
					_value = _value?.replace('T', '')
				}

				newQuery[key] = _value ? _value : ''
			})
			if (temp.length) {
				setFiltersOn(temp)
			}

			let dateFrom = ''
			if ('date' in _filters) {
				dateFrom = getFilterDate(_filters.date)
			}

			return {...state, dateFrom, query: {...state.query, ...newQuery}}
		})
	}

	const filtersReset = () => {
		setFilterQueries(initialState)
		setFiltersOn([])
		setFilters({})
	}


	return (
		<>
			<div className={'w-full gap-3 p-4'}>
				<Filters onClick={() => setFiltersOpen(true)} active={filtersOn.length}>
					<DText>
						{/* <Text tid='filters' /> */}
						<FilterIcon color={theme ==='dark' ? '#D9D9D9' : '#2E3344'} />
					</DText>
				</Filters>
				<FlexCenter style={{ flexWrap: 'wrap', justifyContent: 'flex-start', marginTop: '8px' }}>
					{filtersOn.map(tag => (
						<FilterTag>
							<DText style={{ margin: '0 2px' }}>
								<Text tid={tag.key} />:
							</DText>
							<DText>
								<Text tid={tag.value} />
							</DText>
						</FilterTag>
					))}
				</FlexCenter>

				{hasSearchBar &&
					<Flex align={'center'}>
						<SearchBox
							onKeyDown={onEnterToggled}
							searchValue={searchValue}
							onInputValueChange={(e) => setSearchValue(e?.target?.value)}
							onCloseSearchClicked={onCloseSearchClick}
						/>
						{searchValue && <SubmitSearch onClick={() => requestOrdersByMarket(searchValue)} />}
					</Flex>
				}
			</div>

			{width > TABLET_SIZE && !!headers?.length && (
				<HeaderRow>
					{headers.map((header) => (
						<HeaderColumn key={header.title} width={header.width}>
							<Text tid={header.title} />
						</HeaderColumn>
					))}
				</HeaderRow>
			)}

			{!loading && children}

			{data?.data?.length && totalPages ? (
				<PaginationContainer>
					<Pagination
						activePage={filterQueries.page}
						itemsCountPerPage={10}
						totalItemsCount={totalPages}
						pageRangeDisplayed={3}
						onChange={(p) => onPageChange(p)}
						itemClass={theme === "light" ? "d-page-item" : "page-item"}
						linkClass={theme === "light" ? "d-page-link" : "page-link"}
					/>
				</PaginationContainer>
			) : null}
			<AuthLoading loading={loading} />
			{data?.data?.length === 0 && !loading && (
				<NoDataWrapper top='150px'>
					<img alt=' ' src={require('../../../assets/images/noData.png')} />
				</NoDataWrapper>
			)}
			<AnimatePresence exitBeforeEnter>
				{filtersOpen &&
					<FiltersWrapper ref={filtersRef} variants={variants} animate='in' exit='out' initial='out'>
						<FlexCenter style={{ borderBottom: '1px solid #ffffff15', paddingBottom: '5px' }}>
							<DText style={{ position: 'absolute', right: '20px', top: '20px' }} onClick={filtersReset}>
								<Text tid='delete-filters' />
							</DText>
							<FiltersText color='mainOrange'>
								<Text tid='filters' />
							</FiltersText>
						</FlexCenter>
						<FilterContent>
							{filters?.map(filter => (
								<FiltersRow>
									<DText style={{ margin: '5px 0' }}>
										<Text tid={filter} />
									</DText>
									{FILTERS[filter].map((item, idx) => (
										<FlexCenter style={{ margin: '5px 0' }} onClick={() => onFilterClicked(filter, idx)}>
											<FilterCheckBox active={_filters[filter] === idx } />
											<DText>
												<Text tid={item} />
											</DText>
										</FlexCenter>
									))}
								</FiltersRow>
							))}
						</FilterContent>
						<FlexCenter style={{ justifyContent: 'space-around', marginTop: '10px' }}>
							<FilterButton active onClick={() => onSubmitClicked()}>
								<Text tid='affect' />
							</FilterButton>

							<FilterButton onClick={() => setFiltersOpen(false)}>
								<Text tid='cancel' />
							</FilterButton>

						</FlexCenter>
					</FiltersWrapper>
				}
			</AnimatePresence>
		</>
	)
}


const variants = {
	in: {
		y: 0, transition: { duration: 0.3 }
	},
	out: {
		y: 500, transition: { duration: 0.3 }
	}
}

export default RFilterLayout
