const BASE_URL = process.env.REACT_APP_BASE_URL
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
const HOME = process.env.REACT_APP_HOME
const HAS_DEPOSIT_WITH_ID = parseInt(process.env.REACT_APP_HAS_DEPOSIT_WITH_ID)

export {
    BASE_URL,
    SOCKET_URL,
    HOME,
    HAS_DEPOSIT_WITH_ID
}
