import axios from 'axios'
import { BASE_URL } from '../../constants/urls'
import { getUrlWithQueries } from '../../utils/getUrlWithQueries'

const filterFetch = (data, token, path) => {
	return axios.get(getUrlWithQueries(BASE_URL + path, data), {
		headers: {
			'x-auth-token': token,
		},
	})
}

const normalFetch = (token, path) => {
	return axios.get(BASE_URL + path, {
		headers: {
			'x-auth-token': token,
		},
	})
}

/**
 * Get api
 * @param {string} path
 * @returns {Promise}
 */
const normalFetchWithoutToken = (path) => {

    return axios.get(BASE_URL + path)
}


export { filterFetch, normalFetch, normalFetchWithoutToken }
