import React from 'react'
import CardLayout from '../../layouts/CardLayout'
import {
	RCFlex,
	RCFlexEnd,
	RFlex,
	RFlexBetween,
	RFlexCenter,
	RRow,
	RText,
} from '../../../styles/responsive/Common'
import { Flex } from '../../../styles/CommonStyles'
import { SOCKET_URL } from '../../../core/constants/urls'
import { Market, TextWithMargin } from '../../../styles/OrdersStyle'
import Text from '../../../core/utils/Text'
import { TransactionStatus } from '../../../styles/TransactionHistoryStyles'
import { _formatDate } from '../../../core/utils/dates'
import { formatNumber } from '../../../core/utils/common'
import { CgChevronDown } from 'react-icons/cg'
import TruncateMiddle from '../../common/TruncateMiddle'
import { useQueryContext } from '../../../core/contexts/query'

const RTransactionItem = (props) => {
	const { data: item, isCoin = true } = props

	const { setToast } = useQueryContext()

	const onCopyToClipboard = (hash) => {
		navigator.clipboard.writeText(hash)
		setToast({
			show: true,
			message: 'copy-success',
		})
	}

	return (
		<RRow className={'w-full m-auto px-0'} >
			<Flex align={'center'} className={'gap-3 justify-between w-full'}>
				{!!isCoin && (
					<RFlex style={{ alignItems: 'center' }} className='w-[30%]'>
						<img
							width='20px'
							src={SOCKET_URL + `assets/icon/${item.coin}.png`}
							alt=' '
							style={{ margin: '0 8px' }}
						/>
						<Market className='text-sm'>{item.coin.toUpperCase()}</Market>
					</RFlex>
				)}

				<RCFlex className={`${isCoin ? 'w-[20%]' : 'w-[30%]'}`}>
					<div>
						<Flex flexDirection='col' align={'center'} justify={'start'} className={'text-xs'}>
							{/*{item.flow === 'deposit' ? <BuyIcon size={'8px'} /> : <SellIcon />}*/}
							<TextWithMargin className={'m-0 pr-1'} type={item.flow}>
								<Text tid={item.flow} />
							</TextWithMargin>
						</Flex>
						{/* <RText className={'text-md'}>{item.coin} </RText> */}
					</div>
				</RCFlex>

				{isCoin && (
					<RFlexCenter className='w-[25%]'>
						<TruncateMiddle
							text={item._id}
							frontChars={3}
							backChars={3}
							onClick={onCopyToClipboard.bind(null, item._id)}
						/>
					</RFlexCenter>
				)}

				<RFlexCenter className={`${isCoin ? 'w-[20%]' : 'w-[40%]'}`}>
					{/* <RText className={'pl-2 '} fontSize='0.55rem'>
                            <Text tid='amount' />:
                        </RText> */}
					<TextWithMargin className={'m-0 text-xs text-left'} type={item.flow}>
						{formatNumber(item.amount)}
					</TextWithMargin>
				</RFlexCenter>
				{/* <RFlexBetween>
                        <RText className={'pl-2'} fontSize='0.55rem'>
                            <Text tid='id' />:
                        </RText>
                        <RText
                            className={'text-left'}
                            style={{ lineBreak: 'anywhere' }}
                            fontSize='0.55rem'>
                            {item._id}
                        </RText>
                    </RFlexBetween> */}

				{!isCoin && (
					<Flex flexDirection={'col'} align={'end'} justify={'center'} className='w-[25%]'>
						<TransactionStatus className={'text-xs'} status={item.status}>
							<Text tid={`T${item.status}`} />
						</TransactionStatus>
						<RFlexBetween>
							<RText className={'text-left'} fontSize='0.55rem'>
								{_formatDate(item.createdAt)}
							</RText>
						</RFlexBetween>
					</Flex>
				)}

				<div className='w-[5%]' onClick={() => { props.onClick() }}>
					<CgChevronDown />
				</div>
			</Flex>
		</RRow>
	)
}

export default RTransactionItem
