import axios from 'axios'
import { BASE_URL } from '../../constants/urls'

const postApi = (data, path) => {
	return axios.post(BASE_URL + path, {
		'x-platform': 'web',
		...data,
	})
}

const postApiWithToken = (data, token, path) => {
	return axios.post(BASE_URL + path, data, {
		headers: {
			'x-auth-token': token,
		},
	})
}

export { postApi, postApiWithToken }
