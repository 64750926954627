import React, { useEffect, useRef, useState } from 'react'
import CardLayout from '../../layouts/CardLayout'
import { AnimatePresence } from 'framer-motion'
import useTransactions from '../../../core/hooks/useTransaction'
import RTransactionItem from './RTransactionItem'
import RFilterLayout from '../layouts/RFilterLayout'
import { DetailsWrapper } from '../../../styles/TransactionHistoryStyles'
import TransactionDetails from '../../transactions/TransactionDetails'
import useClickOutside from '../../../core/hooks/useClickOutside'
import MobileModal from '../../modals/MobileModal'

export default function RNewTransactionHistory({ type, flow }) {
    // const [activeTab, setActiveTab] = useState(1)
    const [detailsModal, setDetailsModal] = useState({
        open: false,
        item: null,
    })

    const {
        bankQueries,
        bankFetching,
        bankTransactions,
        walletQueries,
        walletFetching,
        walletTransactions,
        allPages,
        setBankQueries,
        setWalletQueries,
        bankInitialState,
        walletInitialState,
    } = useTransactions()

    useEffect(() => {
        if (type === 'bank') {
            setBankQueries((prevState) => ({
                ...prevState,
                page: 1,
                query: { ...prevState.query, flow: flow },
            }))
        }
        if (type === 'wallet') {
            setWalletQueries((prevState) => ({
                ...prevState,
                page: 1,
                query: { ...prevState.query, flow: flow },
            }))
        }
    }, [type, flow])

    return (
        <>
            <CardLayout width='100%' minHeight='400px' color='#607d8b'>
                <RFilterLayout
                    data={
                        type === 'bank' ? bankTransactions : walletTransactions
                    }
                    totalPages={type === 'bank' ? allPages.BT : allPages.WT}
                    activeTab={type}
                    state={
                        type === 'bank'
                            ? {
                                filterQueries: bankQueries,
                                setFilterQueries: setBankQueries,
                                initialState: bankInitialState,
                                type: 'flow',
                            }
                            : {
                                filterQueries: walletQueries,
                                setFilterQueries: setWalletQueries,
                                initialState: walletInitialState,
                                type: 'flow',
                            }
                    }
                    loading={type === 'bank' ? bankFetching : walletFetching}
                    filters={['date', 'flow', 'status']}>
                    {type === 'bank' ? (
                        bankTransactions?.data?.length ? (
                            <TransactionTable
                                data={bankTransactions.data}
                                // activeTab={activeTab}
                                type={type}
                                setDetailsModal={setDetailsModal}
                            />
                        ) : null
                    ) : walletTransactions?.data?.length ? (
                        <TransactionTable
                            data={walletTransactions.data}
                            // activeTab={activeTab}
                            type={type}
                            setDetailsModal={setDetailsModal}
                        />
                    ) : null}
                </RFilterLayout>
            </CardLayout>
            <MobileModal
                isOpen={detailsModal.open}
                onClose={() => {
                    setDetailsModal({ open: false, item: null })
                }}>
                <TransactionDetails
                    transaction={detailsModal.item}
                    onClose={() => { setDetailsModal({ open: false, item: null }) }}
                    type={type}
                />
            </MobileModal>
        </>
    )
}

const variants = {
    in: {
        y: 0,
        transition: { duration: 1 },
    },
    out: {
        y: 500,
        transition: { duration: 1 },
    },
}

export const TransactionTable = ({
    data,
    activeTab,
    type,
    setDetailsModal,
}) => {
    return (
        <AnimatePresence exitBeforeEnter>
            <div className={'pt-5 '}>
                {data.map((item, idx) => (
                    <RTransactionItem
                        isCoin={type === 'wallet'}
                        data={item}
                        key={idx}
                        onClick={() => {
                            setDetailsModal({ open: true, item })
                        }}
                    />
                ))}
            </div>
        </AnimatePresence>
    )
}
